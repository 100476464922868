@use "../designSystem/common/sassVars" as *;
@use "../components/export/fogList";

.fog {
	@extend %fog;

	margin-bottom: 0;

	--fog-grid-min-size: 200px;

}
