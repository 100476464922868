@use '../../designSystem/common/sassVars' as *;

%fog {
	display: grid;
	gap: 20px;
	margin-bottom: 40px;
	grid-template-columns: repeat(auto-fill, minmax(var(--fog-grid-min-size), 1fr));

	@media (min-width: $scr-sm-min) {
		gap: 30px;
	}

	.fog__item {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		overflow: hidden;
		display: grid;
		grid-template-rows: auto 1fr;
		background-color: #fff;

		&>a {
			position: relative;
			display: block;

			img {
				position: absolute;
				inset: 0;
				height: 100%;
				width: 100%;
				background: var(--grey-100);
			}

			&::after {
				content: '';
				display: block;
				padding-top: calc(138 / 240 * 100%);
			}
		}

		img {
			vertical-align: top;
		}

		div {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
			border: solid 1px var(--grey-400);
			border-top: 0;
			padding: 15px 15px 20px;
			display: flex;
			flex-direction: column;

			img {
				border-radius: 4px;
			}

			figure {
				display: grid;
				grid-template-columns: 28px auto;
				column-gap: 10px;

				b {
					font-size: 14px;
					display: block;
					margin: -3px 0;
					line-height: 16px;

					@media (min-width: $scr-xs-min) {
						line-height: 18px;
						font-size: 16px;
					}
				}

				small {
					font-size: 10px;
					opacity: .6;
					line-height: 14px;
					display: block;
					margin-top: 4px;
				}
			}

			p {
				flex: 1;
				margin: 10px 0 15px;

				a {
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-decoration: none;

					@media (min-width: $scr-xs-min) {
						font-size: 16px;
						line-height: 22px;
					}
				}
			}
		}

		&__review {
			margin-top: 12px;
		}

	}

}
